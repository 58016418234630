import React, { useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ReactPlayer from "react-player/youtube";
import axios from "axios";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import GridWrapper from "../GridWrapper";
import GridElement from "../GridElement";
import { PortalWithState } from "react-portal";
import Image from "next/image";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CancelIcon from "@mui/icons-material/Cancel";
import { NEXT_PUBLIC_YOUTUBE_API_KEY } from "../../utils/constants";
import clsx from "clsx";

dayjs.extend(relativeTime);

interface VideoProps {
  url: string;
  title: string;
  channelTitle: string;
  thumbnail: string;
  publishedAt: string;
  viewCount: number;
}

interface YouTubePlaylistProps {
  blok: {
    playlist_id: string;
    videos_template?: number;
  };
}

const YouTubePlaylist: React.FC<YouTubePlaylistProps> = ({
  blok: { playlist_id, videos_template = 4 },
}) => {
  const [playingIndex, setPlayingIndex] = useState<number | null>(null);
  const [currentVideo, setCurrentVideo] = useState<string>("");
  const [hoveredVideo, setHoveredVideo] = useState<string | null>(null);

  const { data, isError, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["youtubeVideos", playlist_id],
    async ({ pageParam = "" }) => {
      const playlistItemsUrl = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlist_id}&maxResults=${videos_template}&pageToken=${pageParam}&key=${NEXT_PUBLIC_YOUTUBE_API_KEY}`;
      const response = await axios.get(playlistItemsUrl);

      // Extract video IDs
      const videoIds = response.data.items.map(
        (item: { snippet: { resourceId: { videoId: string } } }) =>
          item.snippet.resourceId.videoId
      );

      // Fetch video details
      const videoDetailsUrl = `https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics&id=${videoIds.join(
        ","
      )}&key=${NEXT_PUBLIC_YOUTUBE_API_KEY}`;
      const videoDetailsResponse = await axios.get(videoDetailsUrl);
      const videoDetails = videoDetailsResponse.data.items;

      // Map videos with details
      const mappedVideos = response.data.items.map((item: any) => {
        const videoDetail = videoDetails.find(
          (video: { id: string }) =>
            video.id === item.snippet.resourceId.videoId
        );

        return {
          url: `https://www.youtube.com/watch?v=${item?.snippet?.resourceId?.videoId}`,
          title: item?.snippet?.title,
          channelTitle: item?.snippet?.channelTitle,
          thumbnail:
            item?.snippet?.thumbnails?.maxres?.url ||
            item?.snippet?.thumbnails?.default?.url,
          publishedAt: item?.snippet?.publishedAt,
          viewCount: videoDetail?.statistics?.viewCount,
        };
      });

      return {
        videos: mappedVideos,
        nextPageToken: response.data.nextPageToken,
      };
    },
    {
      enabled: !!playlist_id,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.nextPageToken || null,
    }
  );

  // Flatten the array of pages to access all videos
  const videos = data?.pages.flatMap((page) => page.videos) || [];

  const handleShowMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  if (videos?.length === 0 || isError) return <div />;

  return (
    <GridWrapper className="mb-16">
      <GridElement
        gridLayout={
          videos_template == 4
            ? [
                [961, 1, 16, 2],
                [1401, 1, 32, 2],
              ]
            : [
                [961, 2, 14, 2],
                [1401, 4, 26],
              ]
        }
      >
        {videos_template == 4 && (
          <Swiper
            className="lg:hidden"
            spaceBetween={10}
            slidesPerView={1.25}
            centeredSlides={true}
            loop={false}
            pagination={{ clickable: true }}
            modules={[Pagination]}
            onTransitionStart={() => {
              setPlayingIndex(null);
            }}
            grabCursor
          >
            {videos.map((video: VideoProps, index: number) => (
              <SwiperSlide key={`${video.url}-${index}`}>
                <div className="flex flex-col">
                  <div
                    className="relative rounded-[20px] overflow-hidden aspect-[1.8] w-full h-full max-h-[200px]"
                    onClick={() => {
                      setPlayingIndex((prevIndex) =>
                        prevIndex === index ? null : index
                      );
                    }}
                    onTouchStart={(e) => e.stopPropagation()}
                    onTouchMove={(e) => e.stopPropagation()}
                  >
                    <div
                      className={clsx({
                        "pointer-events-none": playingIndex !== index,
                        "pointer-events-auto": playingIndex === index,
                      })}
                    >
                      <ReactPlayer
                        thumbnail={video.thumbnail}
                        className="absolute inset-0 rounded-[20px]"
                        url={video.url}
                        width="100%"
                        height="100%"
                        playing={playingIndex === index}
                        controls
                        stopOnUnmount
                      />
                    </div>
                  </div>
                  {/* Description */}
                  <div className="mt-2 mb-4 flex flex-col gap-3 text-white">
                    <p className="font-poppins text-[16px] font-normal leading-[24px]">
                      {video.title}
                    </p>
                    <p className="font-poppins text-[12px] font-normal leading-[16px] tracking-[0.36px] text-[#767676]">
                      {video.channelTitle}
                    </p>
                    <p className="font-poppins text-[12px] font-light leading-[16px] tracking-[0.36px] text-[#767676]">
                      {video.viewCount} views •{" "}
                      {dayjs(video.publishedAt).fromNow()}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {videos_template == 16 && (
          <div className="md:grid md:grid-cols-2 md:row-span-1 lg:hidden flex flex-col gap-[15px]">
            {videos.map((video: VideoProps, index: number) => (
              <div
                key={`${video.url}-${index}`}
                className="flex flex-col gap-2"
              >
                <div className="relative rounded-[20px] overflow-hidden aspect-[1.8] w-full h-full max-h-[200px]">
                  <ReactPlayer
                    thumbnail={video.thumbnail}
                    className="absolute inset-0 rounded-[20px]"
                    url={video.url}
                    width="100%"
                    height="100%"
                    playing={playingIndex === index}
                    controls
                    stopOnUnmount
                  />

                  {/* Overlay gradient */}
                  {playingIndex !== index && (
                    <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-black/20 pointer-events-none" />
                  )}
                </div>
                {/* Description */}
                <div className="flex flex-col gap-3 text-white">
                  <p className="font-poppins text-[16px] font-normal leading-[24px]">
                    {video.title}
                  </p>
                  <p className="font-poppins text-[12px] font-light leading-[16px] tracking-[0.36px] text-[#767676]">
                    {video.viewCount} views •{" "}
                    {dayjs(video.publishedAt).fromNow()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}

        <PortalWithState closeOnOutsideClick closeOnEsc>
          {({ openPortal, closePortal, isOpen, portal }) => {
            if (typeof document !== "undefined") {
              document.body.style.overflow = isOpen ? "hidden" : "auto";
            }

            return (
              <div>
                <div className="hidden lg:grid lg:grid-cols-4 lg:row-span-1 lg:gap-y-10 lg:gap-x-3">
                  {videos.map((video: VideoProps, index: number) => (
                    <div
                      key={`${video.url}-${index}`}
                      className="relative flex flex-col"
                    >
                      {/* Video Thumbnail */}
                      <div
                        onClick={(e) => {
                          openPortal(e);
                          setCurrentVideo(video.url);
                        }}
                        onMouseEnter={() => setHoveredVideo(video.url)}
                        onMouseLeave={() => setHoveredVideo(null)}
                        className="relative cursor-pointer aspect-[1.6] w-full h-full max-h-[200px] rounded-[20px] overflow-hidden"
                      >
                        {hoveredVideo === video.url ? (
                          <ReactPlayer
                            url={video.url}
                            width="100%"
                            height="100%"
                            muted
                            controls={false}
                            playing={hoveredVideo === video.url}
                          />
                        ) : (
                          <>
                            {video.thumbnail ? (
                              <Image
                                src={video.thumbnail}
                                sizes="100vw"
                                layout="fill"
                                objectFit="cover"
                              />
                            ) : (
                              <div className="w-full h-full bg-[#272727]" />
                            )}
                            <PlayArrowIcon
                              className="absolute z-[2] inset-x-0 inset-y-0 m-auto text-center"
                              style={{
                                fontSize: 60,
                              }}
                            />
                          </>
                        )}

                        {/* Overlay gradient */}
                        <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-black/20 rounded-[20px]"></div>
                      </div>
                      {/* Description */}
                      <div className="flex flex-col gap-3 mt-[10px] px-5">
                        <p className="max-w-[250px] font-poppins text-[16px] font-light leading-[24px] text-white">
                          {video.title}
                        </p>
                        <p className="font-poppins text-[12px] font-light leading-[16px] tracking-[0.36px] text-[#767676]">
                          {video.channelTitle}
                        </p>
                        <p className="font-poppins text-[12px] font-light leading-[16px] tracking-[0.36px] text-[#767676]">
                          {video.viewCount} views •{" "}
                          {dayjs(video.publishedAt).fromNow()}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                {/* Show more button */}
                {videos_template == 16 && (
                  <div className="flex justify-center mt-10">
                    <button
                      onClick={handleShowMore}
                      className={clsx(
                        "px-[30px] py-[10px] rounded-md bg-[#EC691A] text-white text-center text-[16px] font-normal leading-[24px] tracking-[0.5px]",
                        {
                          "opacity-30 cursor-default": !hasNextPage,
                          "opacity-100 cursor-pointer hover:opacity-60":
                            hasNextPage,
                        }
                      )}
                    >
                      Show more
                    </button>
                  </div>
                )}

                {/* Portal to render the video dialog */}
                {currentVideo &&
                  portal(
                    <>
                      <div
                        className="fixed inset-0 bg-black bg-opacity-90 z-30"
                        onClick={() => {
                          closePortal();
                        }}
                      />
                      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[80vw] h-auto z-30 bg-black bg-opacity-80">
                        <CancelIcon
                          style={{ fontSize: 36 }}
                          className="absolute top-[-30px] right-[-30px] z-20 text-white text-opacity-80 cursor-pointer transition-colors duration-300"
                          onClick={() => {
                            closePortal();
                            setCurrentVideo("");
                          }}
                        />
                        <div className="relative pt-[56.25%] rounded-[20px] overflow-hidden">
                          <ReactPlayer
                            url={currentVideo}
                            width="100%"
                            height="100%"
                            className="absolute top-0 left-0 w-full h-full"
                            playing={isOpen}
                            controls
                          />
                        </div>
                      </div>
                    </>
                  )}
              </div>
            );
          }}
        </PortalWithState>
      </GridElement>
    </GridWrapper>
  );
};

export default YouTubePlaylist;
