// PROD endpoints
export const GOLF_PORTAL_AUTH_API_DEV =
  "https://login.trackmangolf.com/connect/token";
export const GOLF_PORTAL_AUTH_API =
  "https://login.trackmangolf.com/connect/token";
export const GOLF_PORTAL_API_DEV = "https://api.trackmanrange.com/graphql";
export const GOLF_PORTAL_API = "https://api.trackmanrange.com/graphql";
export const FACILITY_ID_LIVE =
  "RmFjaWxpdHkKZDk3MTFmNjlhLWQ4OGUtNDJlNi1hYmIyLTgzOTBjYWFiN2VmNg==";
export const FACILITY_ID_DEV =
  "RmFjaWxpdHkKZDk3MTFmNjlhLWQ4OGUtNDJlNi1hYmIyLTgzOTBjYWFiN2VmNg==";

// DEV endpoints
// export const GOLF_PORTAL_AUTH_API =
//   "https://tm-login-dev.trackmangolfdev.com/connect/token";
// export const GOLF_PORTAL_API =
//   "https://dr-cloud-api-dev.trackmangolfdev.com/graphql/";
// export const GOLF_PORTAL_API_DEV =
//   "https://dr-cloud-api-dev.trackmangolfdev.com/graphql/";
// export const GOLF_PORTAL_AUTH_API_DEV =
//   "https://tm-login-dev.trackmangolfdev.com/connect/token";
// export const FACILITY_ID_LIVE =
//   "RmFjaWxpdHkKZDA5ODgxNmEyLTQxZmUtNDcwOC1hZmRiLTQ0ZjFkNWFiMjUyYQ==";
// export const FACILITY_ID_DEV =
//   "RmFjaWxpdHkKZDA5ODgxNmEyLTQxZmUtNDcwOC1hZmRiLTQ0ZjFkNWFiMjUyYQ==";

// AM TEST TOURNAMENT FACILITY
// export const FACILITY_ID_LIVE =
//   "RmFjaWxpdHkKZDVlYWE0MDM1LTM3MjQtNDYxNS1hZDc1LTIzY2Y2ZmQ1NWM1Zg==";
// export const FACILITY_ID_DEV =
//   "RmFjaWxpdHkKZDVlYWE0MDM1LTM3MjQtNDYxNS1hZDc1LTIzY2Y2ZmQ1NWM1Zg==";

// Marketing test facility
// export const FACILITY_ID_LIVE =
//   "RmFjaWxpdHkKZDBkYWIyMTRmLWFjMzYtNDYzNy1iMGE5LTY1OGUzYzhhNDNlYQ==";
// export const FACILITY_ID_DEV =
//   "RmFjaWxpdHkKZDBkYWIyMTRmLWFjMzYtNDYzNy1iMGE5LTY1OGUzYzhhNDNlYQ==";

// STAGE endpoints
// export const GOLF_PORTAL_API_DEV =
//   "https://tm-cloud-api-staging.azurewebsites.net/graphql";
// export const GOLF_PORTAL_API =
//   "https://tm-cloud-api-staging.azurewebsites.net/graphql";

export const LEADERBOARD_PAGE_SIZE = 50;
export const ROUND_5_PLAYER_INIT_COUNT = 355;

//Season 1 and 2
export const ENTRY_FEE = 100;
export const ENTRY_FEE_WITH_TAX = 130;
export const INITIAL_PURSE = 100000;
export const INITIAL_PURSE_ELEVATED = 150000;
export const FIRST_PLACE_PRICE = 30000;
export const FIRST_PLACE_PRICE_ELEVATED = 50000;
export const AGGREGATE_CTP_LAST = ["$6,000", "$4,000", "$2,000"];
export const AGGREGATE_CTP_FINALE = ["$2,500", "$1,500", "$1,000"];
export const AGGREGATE_CTP = ["$4,000", "$2,500", "$1,500"];
export const AGGREGATE_CTP_4_5_6_ROUNDS = ["$3,000", "$1,500", "$1,000"];
export const BIRDIE_STREAK_LAST = ["$1,500", "$850", "$400"];
export const BIRDIE_STREAK_4_5_6_ROUNDS = ["$1,000", "$500"];
export const BIRDIE_STREAK = ["$1,500", "$1,000", "$500"];
export const LONGEST_DRIVE = ["$2,500", "$1,500", "$1,000"];
export const LONGEST_DRIVE_LAST = ["$2,000", "$1,250", "$500"];
export const LONGEST_DRIVE_4_5_6_ROUNDS = ["$2,000", "$1,000", "$500"];
export const MOST_GIRS = ["$1,500", "$1,000", "$500"];
export const MOST_GIRS_LAST = ["$1,500", "$850", "$400"];
export const SOCIAL_MEDIA = ["$2,000", "$1,000"];
export const SOCIAL_MEDIA_LAST = "$2,000";
export const SOCIAL_MEDIA_FINALE = [
  "$1,000",
  "$1,000",
  "$1,000",
  "$1,000",
  "$1,000",
];
export const PRIZES_FINAL_SEASON_2 = [
  30000, 20000, 15000, 10000, 7500, 5000, 3000, 2000, 1500, 1000,
];

//Season 3
export const ENTRY_FEE_PGA = 50;
export const INITIAL_PURSE_PGA = 25000;
export const CTP_PGA = ["$1,000", "$500", "$300", "$200", "$100"];

export const INITIAL_PURSE_SEASON_3_FINAL = 150000;
export const AGGREGATE_CTP_SEASON_3 = ["$1,500", "$1,000", "$500"];
export const LONGEST_DRIVE_SEASON_3 = ["$1,500", "$750", "$250"];
export const LONGEST_DRIVE_SEASON_3_FINAL = ["$1,000", "$500"];
export const SOCIAL_MEDIA_SEASON_3 = ["$2,000", "$2,000", "$1,000"];
export const CTP_SEASON_3 = ["$1,000", "$500", "$250"];
export const PRIZES_FINAL_SEASON_3 = [
  40000, 25196, 18110, 12992, 9301, 6643, 4733, 3363, 2382, 1682, 1575, 1470,
  1365, 1260, 1155, 1050, 945, 840, 735, 630, 525, 420, 315, 210, 105,
];

export const TOURNAMENT_ID_SEASON_1_FINAL =
  "TXVsdGlSb3VuZFRvdXJuYW1lbnQKZGJlYWFhMDhmLTQ4ZGMtNDY2ZC05MDEwLTBlMTliOWRkMjg1YjpQdWJsaXNoZWQ=";
export const TOURNAMENT_IDS_SEASON_1 = [
  "TXVsdGlSb3VuZFRvdXJuYW1lbnQKZDJiMTlmNWYxLWExMTUtNDQ5Ny1hZTk0LWI5YWY2NTA4OTBkNzpQdWJsaXNoZWQ=",
  "TXVsdGlSb3VuZFRvdXJuYW1lbnQKZGExMzU4YWE3LTE1YmYtNDlkZC04NDlkLWM0YzQ0Njc4M2Y0MTpQdWJsaXNoZWQ=",
  "TXVsdGlSb3VuZFRvdXJuYW1lbnQKZGNiMmQ5MTgwLWNlOWItNDYyYi1hNWU3LTI0ZjhlMTRmMWJjNTpQdWJsaXNoZWQ=",
  "TXVsdGlSb3VuZFRvdXJuYW1lbnQKZGZjNjY4ZmMxLWU2YjMtNDFhNC1iNGY5LWM3ODlmMmFlMzRmZTpQdWJsaXNoZWQ=",
  "TXVsdGlSb3VuZFRvdXJuYW1lbnQKZDE0ZjQ1NWY0LThjMjgtNGFmMy05MDYzLWQ1ZmYzYjA1YzQ4MzpQdWJsaXNoZWQ=",
  "TXVsdGlSb3VuZFRvdXJuYW1lbnQKZGJlYWFhMDhmLTQ4ZGMtNDY2ZC05MDEwLTBlMTliOWRkMjg1YjpQdWJsaXNoZWQ=",
];

export const PGA_TOURNAMENT_ID =
  "TXVsdGlSb3VuZFRvdXJuYW1lbnQKZDlmMDQzOGQ0LWFjMWEtNDJlZS1hNTdjLWJhNTFlNWFkZGY5MjpQdWJsaXNoZWQ=";

export const NEXT_PUBLIC_YOUTUBE_API_KEY =
  "AIzaSyBQofqnLwyQvsgdkxjSzbb7AaC6cZnUqAw";
