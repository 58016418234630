import React, { useMemo, useState } from "react";
import { InView } from "react-intersection-observer";
import CountryFlag from "react-country-flag";
import { motion, AnimatePresence } from "framer-motion";
import { useWindowSize } from "../../hooks/useWindowSize";
import styles from "./OOMLeaderboard.module.css";
import { sbBlok } from "../../types/sb-types";
import GridWrapper from "../GridWrapper/GridWrapper";
import GridElement from "../GridElement/GridElement";
import Dropdown, { Option } from "../Dropdown/Dropdown";
import { groupInnerVariants } from "../Leaderboard/animations";
import { useDebounce } from "../../hooks/useDebounce";
import SearchIcon from "@mui/icons-material/Search";
import clsx from "clsx";

interface OOMLeaderboardProps {
  blok: sbBlok;
  csvFiles: Array<{ name: string; data: Array<any> }>;
  isNews?: boolean;
}

const seasonOptions = [
  { id: 1, label: "Season 1" },
  { id: 2, label: "Season 2" },
  { id: 3, label: "Season 3" },
];

const roundOptions = {
  "season1": [
    { id: 1, label: "Round 1" },
    { id: 2, label: "Round 2" },
    { id: 3, label: "Round 3" },
    { id: 4, label: "Round 4" },
    { id: 5, label: "Round 5" },
    { id: 6, label: "Finale" },
  ],
  "season2": [
    { id: 1, label: "Round 1" },
    { id: 2, label: "Round 2" },
    { id: 3, label: "Round 3" },
    { id: 4, label: "Round 4" },
    { id: 5, label: "Round 5" },
    { id: 6, label: "Round 6" },
  ],
  "season3": [
    { id: 1, label: "Round 1" },
    { id: 2, label: "Round 2" },
    { id: 3, label: "Round 3" },
    { id: 4, label: "Finale" },
  ],
} as Record<string, { id: number; label: string }[]>;

const OOMLeaderboard = ({ blok, csvFiles, isNews }: OOMLeaderboardProps) => {
  const { windowSize } = useWindowSize();
  const [activeRows, setActiveRows] = useState<string[]>([]);
  const [selectedSeason, setSelectedSeason] = useState<number>(
    blok?.selected_season ? +blok?.selected_season : 1
  );
  const [selectedRound, setSelectedRound] = useState(1);
  const [searchText, setSearchText] = useState("");

  const dataFromCSV = isNews
    ? csvFiles.find(
        (file) =>
          file.name.includes(`S${selectedSeason}`) &&
          file.name.includes(`R${selectedRound}`)
      )?.data
    : csvFiles[selectedSeason - 1].data;

  const handleRowClick = (id: string) => {
    setActiveRows((prevRows) =>
      prevRows.includes(id)
        ? prevRows.filter((i) => i !== id)
        : [...prevRows, id]
    );
  };

  const handleSeasonChange = (season: Option) => {
    if (season.id && season.id !== selectedSeason) {
      setSelectedSeason(+season.id);
      setSelectedRound(1);
    }
  };

  const handleRoundChange = (round: Option) => {
    if (round.id && round.id !== selectedRound) {
      setSelectedRound(+round.id);
    }
  };

  const debouncedSearchText = useDebounce(searchText, 300);

  const filteredData = useMemo(() => {
    if (!debouncedSearchText) return dataFromCSV?.slice(1) || []; // Return all rows if no search text
    return (
      dataFromCSV
        ?.slice(1) // Exclude the header row
        .filter((row) =>
          row.some((cell: any) =>
            cell
              ?.toString()
              .toLowerCase()
              .includes(debouncedSearchText.toLowerCase())
          )
        ) || []
    );
  }, [dataFromCSV, debouncedSearchText]);

  const handleSearchInputChange = (event: { target: { value: any } }) => {
    const searchText = event.target.value;
    setSearchText(searchText);
  };

  return (
    <GridWrapper className={styles.root}>
      <GridElement
        gridLayout={[
          [961, 2, 14],
          [1401, 4, 26],
        ]}
      >
        <GridWrapper>
          <GridElement
            gridLayout={[
              [961, 1, 16, 2],
              [1401, 1, 32],
            ]}
          >
            {!isNews && (
              <div className="flex flex-col md:flex-row justify-center items-center gap-[10px] mx-0 mb-[10px] md:mt-14">
                <Dropdown
                  key="seasons"
                  options={seasonOptions}
                  defaultValue={seasonOptions.find(
                    (opt: { id: number; label: string }) =>
                      opt.id === selectedSeason
                  )}
                  onChange={handleSeasonChange}
                />
              </div>
            )}
            {isNews && (
              <div className="flex flex-col md:flex-row justify-center lg:justify-start items-center gap-[10px] mx-0 mb-[10px] md:mt-14">
                <Dropdown
                  key="seasons"
                  options={seasonOptions}
                  defaultValue={seasonOptions.find(
                    (opt: { id: number; label: string }) =>
                      opt.id === selectedSeason
                  )}
                  onChange={handleSeasonChange}
                />

                <Dropdown
                  key={`rounds-${selectedSeason}`}
                  options={roundOptions[`season${selectedSeason}`]}
                  defaultValue={roundOptions[`season${selectedSeason}`].find(
                    (opt: { id: number; label: string }) =>
                      opt.id === selectedRound
                  )}
                  onChange={handleRoundChange}
                />

                <div className="flex items-center w-full max-w-[330px] lg:max-w-[280px] min-h-[45px] text-2xl bg-[#343434] rounded-[10px] cursor-pointer px-4">
                  <input
                    type="search"
                    placeholder="Search for player"
                    value={searchText}
                    onChange={handleSearchInputChange}
                    className="custom-search-input w-full border-none bg-transparent p-0 m-0 text-2xl focus-visible:outline-none"
                  />
                  <SearchIcon
                    fontSize="large"
                    className="text-white cursor-pointer"
                  />
                </div>
              </div>
            )}
          </GridElement>
        </GridWrapper>
      </GridElement>
      <GridElement
        gridLayout={[
          [961, 2, 14, 2],
          [1401, 4, 26, 2],
        ]}
      >
        <div
          className={clsx("pb-32 overflow-y-auto scroll-smooth", {
            "mt-0": isNews,
            "mt-16 md:mt-32": !isNews,
          })}
        >
          {dataFromCSV && dataFromCSV?.length > 0 && (
            <div role="table" className={styles.leaderboardTable}>
              <div role="thead" className={styles.tableHeader}>
                {dataFromCSV[0].slice(0, 4).map((i: string, n: number) => (
                  <div
                    role="th"
                    className={styles.tableHeaderCell}
                    key={`${i}-${n}`}
                  >
                    {n === 1 ? (
                      <span className={styles.thCellLabel}>{i}</span>
                    ) : (
                      i
                    )}
                  </div>
                ))}
              </div>
              <div role="tbody" className={styles.tableBody}>
                {filteredData.map((i: any, index: number) => {
                  const rowID: string =
                    i[0] + i[2]?.replace(/\s/g, "") + i[3] || i[0] + index;

                  const filteredDataFromCSV = dataFromCSV[0].filter(
                    (str: string) => !!str?.length
                  );

                  return (
                    <InView
                      key={`${rowID}-${index}`}
                      threshold={0.5}
                      triggerOnce
                    >
                      {({ inView, ref }) => (
                        <div
                          ref={ref}
                          role="tr"
                          className={`${styles.tableRowGroup}${
                            activeRows.includes(rowID)
                              ? " " + styles.tableRowGroupActive
                              : ""
                          }`}
                        >
                          {inView ? (
                            <div>
                              <div
                                role="tr"
                                className={styles.tableRow}
                                onClick={() => handleRowClick(rowID)}
                              >
                                {i.slice(0, 4).map((d: any, n: number) => (
                                  <div
                                    role="td"
                                    className={styles.tableRowCell}
                                    key={`${index}-${n}-${d}`}
                                  >
                                    {n === 1 ? (
                                      d ? (
                                        <CountryFlag
                                          countryCode={d}
                                          style={{ fontSize: "1.5em" }}
                                          svg
                                        />
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      <span className={styles.data}>{d}</span>
                                    )}
                                  </div>
                                ))}
                              </div>

                              <AnimatePresence>
                                {activeRows.includes(rowID) && (
                                  <motion.div
                                    role="rowgroup"
                                    className={`${styles.tableRowGroupInner}${
                                      filteredDataFromCSV.length > 10 &&
                                      windowSize.width < 961
                                        ? " " + styles.tableRowGroupInnerLarge
                                        : ""
                                    }`}
                                    variants={groupInnerVariants}
                                    initial="initial"
                                    animate={
                                      activeRows.includes(rowID)
                                        ? "open"
                                        : "initial"
                                    }
                                    exit="initial"
                                  >
                                    <div
                                      className={
                                        styles.tableRowGroupInnerContent
                                      }
                                    >
                                      {filteredDataFromCSV.length <= 10 ? (
                                        <>
                                          <div
                                            role="thead"
                                            className={styles.tableHeader}
                                          >
                                            {filteredDataFromCSV
                                              .slice(
                                                4,
                                                filteredDataFromCSV.length
                                              )
                                              .map((d: string, n: number) => (
                                                <div
                                                  role="td"
                                                  className={
                                                    styles.tableRowCell
                                                  }
                                                  key={`table-header-${d}-${n}`}
                                                >
                                                  <span className={styles.data}>
                                                    {d}
                                                  </span>
                                                </div>
                                              ))}
                                          </div>
                                          <div
                                            role="tr"
                                            className={styles.tableRow}
                                          >
                                            {i
                                              .slice(
                                                4,
                                                filteredDataFromCSV.length
                                              )
                                              .map((d: string, n: number) => (
                                                <div
                                                  role="td"
                                                  className={
                                                    styles.tableRowCell
                                                  }
                                                  key={`tr-${n}-${d}`}
                                                >
                                                  <span className={styles.data}>
                                                    {d}
                                                  </span>
                                                </div>
                                              ))}
                                          </div>
                                        </>
                                      ) : windowSize.width > 960 ? (
                                        <>
                                          <div
                                            role="thead"
                                            className={styles.tableHeader}
                                          >
                                            {filteredDataFromCSV
                                              .slice(4, dataFromCSV[0].length)
                                              .map((d: string, n: number) => (
                                                <div
                                                  role="td"
                                                  className={
                                                    styles.tableRowCell
                                                  }
                                                  key={`table-header-${n}-${d}`}
                                                >
                                                  <span className={styles.data}>
                                                    {d}
                                                  </span>
                                                </div>
                                              ))}
                                          </div>
                                          <div
                                            role="tr"
                                            className={styles.tableRow}
                                          >
                                            {i
                                              .slice(
                                                4,
                                                filteredDataFromCSV.length
                                              )
                                              .map((d: string, n: number) => (
                                                <div
                                                  role="td"
                                                  className={
                                                    styles.tableRowCell
                                                  }
                                                  key={`table-row-${n}-${d}`}
                                                >
                                                  <span className={styles.data}>
                                                    {d}
                                                  </span>
                                                </div>
                                              ))}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            role="thead"
                                            className={styles.tableHeader}
                                          >
                                            {filteredDataFromCSV
                                              .slice(4, 7)
                                              .map((d: string, n: number) => (
                                                <div
                                                  role="td"
                                                  className={
                                                    styles.tableRowCell
                                                  }
                                                  key={`thead-td-${n}-${d}`}
                                                >
                                                  <span className={styles.data}>
                                                    {d}
                                                  </span>
                                                </div>
                                              ))}
                                          </div>
                                          <div
                                            role="tr"
                                            className={styles.tableRow}
                                          >
                                            {i
                                              .slice(4, 7)
                                              .map((d: string, n: number) => (
                                                <div
                                                  role="td"
                                                  className={
                                                    styles.tableRowCell
                                                  }
                                                  key={`tr-td-${n}-${d}`}
                                                >
                                                  <span className={styles.data}>
                                                    {d}
                                                  </span>
                                                </div>
                                              ))}
                                          </div>
                                          <div
                                            role="thead"
                                            className={styles.tableHeader}
                                          >
                                            {filteredDataFromCSV
                                              .slice(7, 11)
                                              .map((d: string, n: number) => (
                                                <div
                                                  role="td"
                                                  className={
                                                    styles.tableRowCell
                                                  }
                                                  key={`thead-row-cell-${n}-${d}`}
                                                >
                                                  <span className={styles.data}>
                                                    {d}
                                                  </span>
                                                </div>
                                              ))}
                                          </div>
                                          <div
                                            role="tr"
                                            className={styles.tableRow}
                                          >
                                            {i
                                              .slice(7, 11)
                                              .map((d: string, n: number) => (
                                                <div
                                                  role="td"
                                                  className={
                                                    styles.tableRowCell
                                                  }
                                                  key={`tr-cell-${n}-${d}`}
                                                >
                                                  <span className={styles.data}>
                                                    {d}
                                                  </span>
                                                </div>
                                              ))}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </motion.div>
                                )}
                              </AnimatePresence>
                            </div>
                          ) : (
                            <div className={styles.tableRow}></div>
                          )}
                        </div>
                      )}
                    </InView>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </GridElement>
    </GridWrapper>
  );
};

export default OOMLeaderboard;
